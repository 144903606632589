<template>
  <div class="bg-blue-50 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="money-bill-alt"
              type="fas"
              class="text-red-500 text-3xl"
              >
              </faIcon>
              Lista de abonos anulados
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Listado de los abonos anulados.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="anulacionesAbonos/anulacionesAbonos"
      :useStorage="true"
      :columnFilters="true"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
    <template v-slot:abono%montoAbono="data">
      <p class="text-gray-900 font-medium text-xs 2xl:text-base whitespace-no-wrap">
        {{ `$ ${formatearCifra(data.value)}` }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path=""
      :meta="meta"
      :links="links"
      action="anulacionesAbonos/paginateAnulacionesAbonos"
      v-if="meta && meta.last_page > 1"
    />
  </div>
</template>

<script>
import ListTable from "@/components/ListTable";
import Pagination from "@/components/Pagination";

import { mapGetters } from "vuex";
import { separarPorMiles } from '@/utils/helpers';

import store from "@/store/index";

export default {
    name: "ListaAnulacionesAbonos",
    setup() {
      let initials = store.getters['anulacionesAbonos/filters']
      let dates = store.getters['anulacionesAbonos/dates']
      return {
        fields: {
          'abono%idAbono' : 'n°',
          'abono%fecha' : 'fecha ingreso',
          'abono%hora' : 'hora ingreso',
          'usuario%name' : 'usuario',
          'fecha' : 'fecha anulación',
          'hora' : 'hora anulación',
          'abono%montoAbono' : 'valor abono',
          'abono%medioPago' : 'medio pago'
        },
        filters: [
          {
            "placeholder": "N°",
            "type": "number",
            "field": "ab_id",
            "initial": initials && initials.ab_id ? initials.ab_id.value : ""
          },
          {
            "type": "none",
          },
          {
            "placeholder": "Por fecha",
            "field": "created_at",
            "type": "date-range",
            "initial": dates ? dates : null
          },
          {
            "type": "none",
          }
        ],
      }
    },
    data() {
        return {
          rows: []
        }
    },
    computed: {
    ...mapGetters("anulacionesAbonos", ["error", "meta", "links"]),
    },
    methods: {
      cargarListaAbonos(){
        store.dispatch("anulacionesAbonos/getAnulacionesAbonos", 1);
      },
      setDates(dates) {
        store.dispatch("anulacionesAbonos/setDates", dates);
        store.dispatch("anulacionesAbonos/getAnulacionesAbonos", 1);
      },
      setFilters(filters) {
        store.dispatch("anulacionesAbonos/setFilters", filters);
        this.cargarListaAbonos();
      },
      formatearCifra(cifra) {
        return separarPorMiles(cifra);
      }
    },
    beforeRouteEnter(to, from, next) {
        const currentPage = parseInt(to.query.page) || 1
        store.dispatch("anulacionesAbonos/getAnulacionesAbonos", currentPage).then(() => {
          to.params.page = currentPage;
          next();
        })
    },
    components: {
        ListTable,
        Pagination,
    }
}
</script>